import inquirySuccessAnimation from '../lottiefiles/inquiry-success.json';
import LottieComp from '../lottie/lottie';
import styles from './success.module.css';

export const Success = () => {
  return (
    <div className={styles.success}>
      <LottieComp src={JSON.stringify(inquirySuccessAnimation)} />
    </div>
  );
};
