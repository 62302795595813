import React from 'react';
import { Success } from '../success/success';
import { Modal } from 'react-aria-components';
import Sprite from '../../sprites/sprite';

interface SuccessModalProps {
  isSuccessOpen: boolean;
  onClose: VoidFunction;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({ isSuccessOpen, onClose }) => {
  return (
    <Modal
      isOpen={isSuccessOpen}
      onOpenChange={onClose}
      shouldCloseOnInteractOutside={(el) => el.id === 'outside'}
      className="w-full h-full bg-black/50 fixed top-0 left-0 z-30 flex flex-col items-center justify-center"
    >
      <div
        id="inquiry-success-modal"
        className="bg-white relative p-12 rounded-xl w-[90%] max-w-[600px]"
      >
        <Success />
        <ModalCloseButton onClose={onClose} />
        <h2 className="text-center text-2xl font-serif text-orange-400 mt-8 font-medium">
          Your booking has been received!
        </h2>
        <p className="text-center max-w-120 m-auto mt-4 px-2">
          Thank you for registering to attend our Vedic Havan.
          <br />
          <br />
          We are excited to see you soon. If you have any further queries please contact
          us on our email at info@vihangamyog.uk or call us on +44 7405 352695.
        </p>
        <div className="flex flex-row items-center gap-4 w-full justify-center mt-10">
          <button
            onClick={onClose}
            className="px-7 py-4 text-xs tracking-widest uppercase font-sans font-semibold bg-yellow-200 rounded-md"
          >
            <p>Dismiss</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

interface ModalCloseButtonProps {
  onClose: VoidFunction;
}

export const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({ onClose }) => {
  return (
    <button className="absolute right-1 top-1 sm:right-4 sm:top-4" onClick={onClose}>
      <Sprite name="close" width="18" height="18" className="w-6 h-6 sm:w-8 sm:h-8" />
    </button>
  );
};
