import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { havanStore } from '../../store/havan-store';
import { useHavanStore } from '../../store/use-havan-store';
import { Modal } from 'react-aria-components';
import { ModalCloseButton } from '../success/success-modal';
import clsx from 'clsx';
import { useFetcher } from '@remix-run/react';
import { ActionResponse } from '../../root';

interface BookAKundModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  location: string;
  eventUrl: string;
}

export const BookAKundModal: React.FC<BookAKundModalProps> = ({
  isOpen,
  onOpenChange,
  location,
  eventUrl,
}) => {
  useHavanStore('vy-uk-havan-store');
  const [isLoading, setIsLoading] = useState(false);
  const title = useMemo(
    () =>
      location === 'Leicester' ? (
        <>
          Yajna at Leicester 9<sup>th</sup> Aug
        </>
      ) : (
        <>
          Yajna at Leeds 17<sup>th</sup> Aug
        </>
      ),
    [location],
  );

  const fetcher = useFetcher({ key: 'vy-uk-havan-submission' });
  const data = fetcher.data as ActionResponse;

  useEffect(() => {
    if (data && !data.success) {
      havanStore.setError(data.error);
      setIsLoading(false);
    }
  }, [data]);

  const error = havanStore.getError();

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    fetcher.submit(
      { ...havanStore.state(), location, intent: 'payment' },
      { method: 'post' },
    );
  }, [fetcher, location]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="w-full h-full bg-black/75 fixed top-0 left-0 z-20 flex flex-col items-center justify-center"
    >
      <div className="bg-[#F5F5F5] relative w-[95%] h-[90%] overflow-y-auto max-h-[620px] sm:max-h-[560px] max-w-[600px] rounded-md px-6 py-6 sm:px-12 sm:py-12">
        <ModalCloseButton onClose={() => onOpenChange(false)} />
        <h2 className="text-center sm:text-left text-xl sm:text-2xl font-bold">
          {title}
        </h2>
        <p className="mt-2 text-center sm:text-left">
          Book your spot to experience this auspicious, Ancient Vedic practice.
        </p>
        {eventUrl ? (
          <a
            href={eventUrl}
            className="text-blue-500 underline inline-block"
            target="_blank"
            rel="noreferrer"
          >
            View event details &rarr;
          </a>
        ) : null}
        <form className="mt-6 flex flex-col">
          <fieldset>
            <div>
              <label htmlFor="booking-name">Name*</label>
              <input
                id="booking-name"
                name="name"
                required
                className="rounded-md mt-1 w-full px-4 py-4 outline-none"
                placeholder="Enter your name..."
                value={havanStore.state().name}
                onChange={(e) => havanStore.changeName(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="booking-guests">Number of guests*</label>
              <div className="mt-1 rounded-md w-full px-4 py-4 bg-white flex flex-row items-center">
                <input
                  name="guests"
                  id="booking-guests"
                  className="w-full outline-none bg-white"
                  placeholder="Select number of guests"
                  type="number"
                  value={havanStore.state().guests}
                  onChange={(e) => havanStore.changeGuests(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="mt-3">
              <label htmlFor="booking-quantity">Number of kund*</label>
              <div className="mt-1 rounded-md w-full px-4 py-4 bg-white flex flex-row items-center">
                <input
                  name="quantity"
                  id="booking-quantity"
                  className="w-full bg-white outline-none"
                  placeholder="Select number of Kund"
                  type="number"
                  value={havanStore.state().quantity}
                  onChange={(e) => havanStore.changeQuantity(parseInt(e.target.value))}
                />
              </div>
            </div>
          </fieldset>
          <button
            className={clsx(
              'mt-8 flex flex-row items-center gap-2 bg-yellow-50 pr-4 rounded-md self-end',
              {
                'opacity-50': isLoading,
              },
            )}
            onClick={onSubmit}
            disabled={isLoading}
            type="button"
          >
            <img src={'/gc.png'} alt="Gocardless logo" className="w-11 rounded-md" />
            Continue to checkout
          </button>
          {error ? <span className="text-red-400">{error}</span> : null}
        </form>
      </div>
    </Modal>
  );
};
