import React, { useCallback, useEffect, useState } from 'react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
} from '@remix-run/react';
import { Banner } from './components/banner/banner';
import Sprite from './sprites/sprite';
import { havanDialog } from './state/havan-dialog';
import { useSnapshot } from 'valtio';
import { ActionFunctionArgs, json, redirect, TypedResponse } from '@remix-run/cloudflare';
import { SuccessModal } from './components/success/success-modal';
import { goToSelector } from './routes/_index';
import { BookAKundModal } from './components/booking/book-a-kund-modal';
import { isRetryableNetworkError, retry } from './network/retry';
import { FACEBOOK, INSTAGRAM, TWITTER } from './components/footer/footer';

export const STRIPE_HAVAN_PAYMENT = 'https://buy.stripe.com/fZedTF2zB9vD9Lq001';
export const STRIPE_HAVAN_PAYMENT_LEEDS = 'https://buy.stripe.com/5kAeXJcabdLTbTyaEH';
export const HAVAN_BROCHURE = 'https://bit.ly/vy-uk-havan-august';

export type ActionResponse =
  | {
      success: true;
      url: string;
    }
  | {
      success: false;
      error: string;
    };

export const action = async ({
  context,
  request,
}: ActionFunctionArgs): Promise<TypedResponse<ActionResponse>> => {
  const env = context.cloudflare.env as { API_URL: string };

  const formData = await request.formData();
  const intent = formData.get('intent');

  if (intent === 'donation') {
    const amount = formData.get('amount');
    if (!amount) {
      return json({ success: false, error: 'Amount not provided' });
    }

    const donationAmount = parseInt(amount.toString());
    if (isNaN(donationAmount)) {
      return json({ success: false, error: 'Invalid amount provided' });
    }

    try {
      const response = await retry(
        'create-donation',
        () =>
          fetch(`${env.API_URL}/donation`, {
            body: JSON.stringify({ amount: donationAmount }),
            method: 'POST',
            headers: { 'content-type': 'application/json' },
          }),
        {
          shouldRetryOnSuccess: isRetryableNetworkError,
        },
      );

      const { url } = await response.json<{ url: string }>();
      return redirect(url);
    } catch (e) {
      return json({ success: false, error: 'Something went wrong' });
    }
  } else {
    const name = formData.get('name');
    const coupon = formData.get('coupon');
    const quantity = formData.get('quantity');
    const guests = formData.get('guests');
    const location = formData.get('location');

    if (!quantity) {
      return json({ success: false, error: 'Quantity not provided' });
    }
    if (!guests) {
      return json({ success: false, error: 'Guests not provided' });
    }
    if (!name) {
      return json({ success: false, error: 'Name not provided' });
    }
    if (!location) {
      return json({ success: false, error: 'Location not provided' });
    }

    const payment = {
      quantity: parseInt(quantity.toString()),
      guests: parseInt(guests.toString()),
      name,
      coupon: coupon?.toString() ?? '',
      location: location.toString(),
    };

    if (isNaN(payment.quantity)) {
      return json({ success: false, error: 'Invalid quantity provided' });
    }
    if (isNaN(payment.guests)) {
      return json({ success: false, error: 'Invalid guests provided' });
    }
    if (location !== 'Leeds' && location !== 'Leicester') {
      return json({ success: false, error: 'Invalid location provided' });
    }

    try {
      const response = await retry(
        'create-payment',
        () =>
          fetch(`${env.API_URL}/payment`, {
            body: JSON.stringify(payment),
            method: 'POST',
            headers: { 'content-type': 'application/json' },
          }),
        {
          shouldRetryOnSuccess: isRetryableNetworkError,
        },
      );

      if (coupon && response.status === 400) {
        return json({ success: false, error: 'Coupon code not found' });
      }

      const { url } = await response.json<{ url: string }>();
      return redirect(url);
    } catch (e) {
      return json({ success: false, error: 'Something went wrong' });
    }
  }
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { isOpen, location, eventUrl } = useSnapshot(havanDialog);
  const navigate = useNavigate();

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      havanDialog.isOpen = isOpen;
      navigate('/', { replace: true, preventScrollReset: true });
    },
    [navigate],
  );

  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsSuccessOpen(false);
    navigate('/', { replace: true, preventScrollReset: true });
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isPaymentSuccessful = Boolean(params.get('paymentSuccess'));
    const isDialogOpen = Boolean(params.get('showHavanDialog'));
    const location = params.get('location');

    setIsSuccessOpen(isPaymentSuccessful);
    havanDialog.isOpen = isDialogOpen;
    if (isDialogOpen) {
      const acceptableLocation = location === 'Leeds' || location === 'Leicester';

      havanDialog.location = acceptableLocation ? location : 'Leicester';
      havanDialog.eventUrl =
        location === 'Leicester' ? 'https://www.facebook.com/events/346225531871289' : '';
    }
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {/* <!-- Google tag (gtag.js) --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-S163F97CR7"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-S163F97CR7');`,
          }}
        />
      </head>
      <body>
        <SuccessModal isSuccessOpen={isSuccessOpen} onClose={onClose} />
        <Banner
          icon={<Sprite name="audio" width="34" height="26" />}
          announcement={
            <span className="flex flex-row items-center justify-center gap-1 flex-wrap">
              <span>
                Join us on the 17<sup>th</sup> of August for a
              </span>
              <button
                className="underline cursor-pointer font-bold"
                onClick={() => goToSelector('havan-section')}
              >
                Vedic Yajna
              </button>{' '}
              <span className="text-2xl">@</span>
              <a
                href="https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/VY%20UK%20Hawan%20Leeds.pdf"
                target="_blank"
                rel="noreferrer"
                download
                className="flex flex-row items-center gap-1.5 relative underline font-bold"
              >
                <button>Leeds</button>
                <Sprite name="download" width="55" height="56" className="w-4 h-4" />
              </a>
            </span>
          }
        />
        <BookAKundModal
          location={location}
          eventUrl={eventUrl}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        />
        {children}
        <ScrollRestoration />
        <Scripts />
        <Socials />
      </body>
    </html>
  );
}

const Socials = () => {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const footer = document.querySelector('#footer');
    if (footer) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsFooterVisible(entry.isIntersecting);
        },
        { threshold: 0.1 },
      );

      observer.observe(footer);

      return () => {
        observer.unobserve(footer);
      };
    }
  }, []);

  if (isFooterVisible) return null;

  return (
    <div className="flex flex-col fixed right-0 top-1/2 -translate-y-1/2 bg-white rounded-l-lg px-2 py-4 shadow-lg z-20">
      <ul className="flex flex-col list-none gap-3 pt-2 xs:pt-0 items-center">
        <li>
          <a href={FACEBOOK} target="_blank" rel="noreferrer">
            <Sprite
              name="facebook"
              width="64"
              height="64"
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </a>
        </li>
        <li>
          <a href={INSTAGRAM} target="_blank" rel="noreferrer">
            <img src="/instagram.png" alt="Instagram" className="w-6 h-6 md:w-8 md:h-8" />
          </a>
        </li>
        <li>
          <a href={TWITTER} target="_blank" rel="noreferrer">
            <Sprite
              name="twitter"
              width="64"
              height="64"
              className="w-4 h-4 md:w-6 md:h-6"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default function App() {
  return <Outlet />;
}
