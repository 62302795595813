interface HavanData {
  quantity: number;
  guests: number;
  name: string;
  acceptedConditions: boolean;
  coupon: string;
}
type ListenerMap = Map<string, VoidFunction>;

const initialData = {
  quantity: 1,
  guests: 1,
  acceptedConditions: false,
  name: '',
  coupon: '',
};

class HavanStore {
  private data: HavanData;
  private error: string = '';
  private listeners: ListenerMap = new Map();
  onChange(id: string, listener: VoidFunction) {
    this.listeners.set(id, listener);
  }
  removeOnChange(id: string) {
    this.listeners.delete(id);
  }
  clearListeners() {
    this.listeners.clear();
  }

  constructor() {
    this.data = initialData;
  }

  changeQuantity(quantity: number) {
    if (isNaN(quantity)) {
      this.mutate(() => {
        this.data.quantity = 1;
      });
    }

    if (quantity < 1) {
      return;
    }
    if (quantity > 5) {
      return;
    }

    this.mutate(() => {
      this.data.quantity = quantity;
    });
  }

  changeName(name: string) {
    this.mutate(() => {
      this.data.name = name;
    });
  }

  changeGuests(guests: number) {
    if (isNaN(guests)) {
      this.mutate(() => {
        this.data.guests = 1;
      });
    }

    if (guests < 1) {
      return;
    }

    this.mutate(() => {
      this.data.guests = guests;
    });
  }

  setAcceptedConditions(acceptedConditions: boolean) {
    this.mutate(() => {
      this.data.acceptedConditions = acceptedConditions;
    });
  }

  reset() {
    this.mutate(() => {
      this.data = initialData;
    });
  }

  private mutate(mutator: () => undefined) {
    this.error = '';
    mutator();

    for (const listener of this.listeners.values()) {
      listener();
    }
  }

  private mutateError(mutator: () => undefined) {
    mutator();

    for (const listener of this.listeners.values()) {
      listener();
    }
  }

  setError(error: string) {
    this.mutateError(() => {
      this.error = error;
    });
  }

  setCoupon(coupon: string) {
    this.mutate(() => {
      this.data.coupon = coupon;
    });
  }

  getError() {
    return this.error;
  }

  state() {
    return this.data;
  }
}

export const havanStore = new HavanStore();
